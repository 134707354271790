/* @flow */

import React, { useContext, useMemo } from "react";
import styles from "./styles.scss";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import Field, { SearchableDropdownField } from "components/Field";

type Props = {
  type: "billing" | "shipping",
  className?: string,
};

export const ContactInfo = (): React$Node => {
  const t = useTranslate();

  return (
    <div className={styles.row}>
      <div className={styles.s50}>
        <Field alwaysValidate name="email" autoComplete="email" label={t("ACCOUNT.EMAIL")} />
      </div>
      <div className={styles.s50}>
        <Field alwaysValidate name="billing.telephone" autoComplete="tel" label={t("ADDRESS.TELEPHONE")} />
      </div>
    </div>
  );
};

const Address = ({ type, className }: Props): React$Node => {
  const t = useTranslate();
  const { info: { countries, locale } } = useContext(StoreInfoContext);

  const sortedCountries = useMemo(() => {
    return countries.slice().sort((a, b) => {
      return a.name.localeCompare(
        b.name,
        locale.split("_")[0],
        { sensitivity: "base" }
      );
    });
  }, [countries, locale]);

  return (
    <div className={className}>
      <div className={styles.row}>
        <div className={styles.s50}>
          <Field name={`${type}.firstname`} autoComplete="given-name" label={t("ADDRESS.FIRSTNAME")} variant="float" />
        </div>
        <div className={styles.s50}>
          <Field name={`${type}.lastname`} autoComplete="family-name" label={t("ADDRESS.LASTNAME")} variant="float" />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.s50}>
          <Field name={`${type}.street.0`} autoComplete="address-line1" label={t("ADDRESS.STREET_1")} variant="float" />
        </div>
        <div className={styles.s50}>
          <Field name={`${type}.street.1`} autoComplete="address-line2" label={t("ADDRESS.STREET_2")} variant="float" />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.s33}>
          <Field name={`${type}.postcode`} autoComplete="postal-code" label={t("ADDRESS.POSTCODE")} variant="float" />
        </div>
        <div className={styles.s33}>
          <Field name={`${type}.city`} autoComplete="address-level2" label={t("ADDRESS.CITY")} variant="float" />
        </div>
        <div className={styles.s33}>
          {sortedCountries.length > 1 &&
            <SearchableDropdownField
              variant="countryDropdown"
              label={t("ADDRESS.COUNTRY")}
              className={styles.countries}
              name={`${type}.countryCode`}
              placeholder=""
              autoComplete="country"
              items={sortedCountries} />
          }
        </div>
      </div>
    </div>
  );
};

export default Address;
