/* @flow */

import React, { useEffect } from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { useLocation, useHistory } from "react-router";
import usePrevious from "helpers/use-previous";
import useCheckoutSteps from "helpers/use-checkout-steps";
import Nav from "./Nav";
import { QuoteData } from "data";
import { setQuoteDiscountCode } from "state/quote";
import { useData, useSendMessage } from "crustate/react";
import Cookies from "js-cookie";
import { MODE } from "state/view-mode";
import { useUi } from "helpers/ui";
import SystemMessages from "components/SystemMessages";
import ChevronIcon from "icons/chevron.svg";

import styles from "./styles.scss";

const AppHeader = (): React$Node => {
  const location = useLocation();
  const { setHamburgerOpen, setSubNavOpen, setViewMode } = useUi();
  const sendMessage = useSendMessage();
  const quoteData = useData(QuoteData);
  const prevQuoteData = usePrevious(quoteData);
  const onCheckout = (location.pathname).includes("checkout") &&
    !(location.pathname).includes("success");

  // Open mini cart when adding to cart
  useEffect(() => {
    if (location.pathname === "/checkout" ||
        !prevQuoteData ||
        (quoteData.state !== "LOADED" || prevQuoteData.state !== "ADDING_ITEM")) {
      return;
    }

    const count = quoteData.data.items.reduce((a, { qty }) => a + qty, 0);
    const prevCount = prevQuoteData.data.items.reduce((a, { qty }) => a + qty, 0);

    if (count > prevCount) {
      setViewMode(MODE.CART);
      setSubNavOpen(false);
      setHamburgerOpen(false);
    }
  }, [setViewMode, prevQuoteData, quoteData, location, setSubNavOpen, setHamburgerOpen]);

  useEffect(() => {
    const coupon = Cookies.get("coupon");
    if (quoteData.state === "LOADED" && quoteData.data && quoteData.data.items.length > 0 && !(quoteData.data.coupon) && coupon !== undefined) {
      sendMessage(setQuoteDiscountCode(coupon));
    }
  }, [quoteData, sendMessage]);

  return (
    <div className={cn(styles.block, { [styles.onCheckout]: onCheckout })}>
      <SystemMessages />
      <Nav onCheckout={onCheckout} />
      {onCheckout && <MobileCheckout />}
    </div>
  );
};

const MobileCheckout = (): React$Node => {
  const t = useTranslate();
  const history = useHistory();
  const { steps, currentStep } = useCheckoutSteps();

  return (
    <div className={styles.mobileCheckout}>
      <div className={styles.content}>
        <div className={styles.back} onClick={() => history.goBack()}><ChevronIcon /></div>
        <div className={styles.steps}>
          <span className={styles.counter}>{`${currentStep.key} ${t("CART.OF")} ${steps.length}`}</span>
          <span className={styles.title}>{currentStep.title}</span>
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
